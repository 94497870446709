import React from 'react'
import { FAQPageJsonLd } from 'next-seo'
import styled, { css } from 'styled-components'
import { Heading, Text, media } from '@beequip/hexagon'
import { CenteredWrapper, CenteredHeading } from './layout/Centered'
import { ArrowDownIcon } from './layout/ArrowDownIcon'
import { Container } from './layout/Container'

interface ItemProps {
    firstInRow?: boolean
}

const IntroSection = styled.div(
    ({ theme }) => css`
        padding: ${theme.spacing[4]}px ${theme.spacing[3]}px;
        background-color: ${theme.colors.grey[5]};

        ${media.m`
            padding: ${theme.spacing[5]}px ${theme.spacing[3]}px;
        `}
    `
)

const IntroText = styled(Text)`
    text-align: center;

    ${media.s`
        width: 40%;
    `}
`

const ItemsSection = styled.div(
    ({ theme }) => css`
        background-color: ${theme.colors.white};
    `
)

const Item = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 0 ${theme.spacing[5]}px 0;
        padding: ${theme.spacing[3]}px 0;

        ${media.m`
            align-items: center;
            flex-direction: row;
            padding: ${theme.spacing[5]}px 0;
        `}
    `
)

const Image = styled.img<ItemProps>(
    ({ firstInRow, theme }) => css`
        display: block;
        flex: 1;
        width: 100%;
        margin-bottom: ${theme.spacing[3]}px;

        ${media.m`
            margin: ${firstInRow ? '0 80px 0 0' : 0};
            order: ${firstInRow ? -1 : 1};
        `}
    `
)

const ItemText = styled.div<ItemProps>(
    ({ firstInRow }) => css`
        flex: 1;

        ${media.m`
            margin: ${firstInRow ? '0 80px 0 0' : 0};
        `}
    `
)

export const Explanation = () => (
    <>
        <IntroSection>
            <CenteredWrapper>
                <CenteredHeading>Wat is de waardecheck?</CenteredHeading>
                <IntroText bottomMargin={4}>
                    De waardecheck is een gratis online tool van Beequip. Binnen
                    enkele stappen krijg je een geschatte waarde van jouw
                    machine.
                </IntroText>
                <ArrowDownIcon />
                <FAQPageJsonLd
                    mainEntity={[
                        {
                            questionName: 'Wat is de waardecheck?',
                            acceptedAnswerText:
                                'De waardecheck is een gratis online tool van Beequip. Binnen enkele stappen krijg je een geschatte waarde van jouw machine.',
                        },
                    ]}
                />
            </CenteredWrapper>
        </IntroSection>
        <ItemsSection>
            <Container maxWidth={926}>
                <Item>
                    <Image
                        firstInRow
                        src="/illustration-value-line-truck.svg"
                    />
                    <ItemText>
                        <Heading size={3}>
                            Gebaseerd op een betrouwbare restwaardelijn
                        </Heading>
                        <Text>
                            De waardebepaling is gebaseerd op specifieke
                            gegevens van jouw machine en data van Beequip en
                            RDW. Dit zijn onder andere het merk, model, de
                            aanschafwaarde en het bouwjaar van de machine.
                        </Text>
                    </ItemText>
                </Item>
                <Item>
                    <Image src="/illustration-valuation-truck.svg" />
                    <ItemText firstInRow>
                        <Heading size={3}>Waardebepaling, geen taxatie</Heading>
                        <Text>
                            De waardebepaling is geen taxatie. Het betreft een
                            indicatie van de waarde van je machine. Wij proberen
                            zo nauwkeurig mogelijk een waardebepaling te
                            berekenen.
                        </Text>
                    </ItemText>
                </Item>
                <Item>
                    <Image
                        firstInRow
                        src="/illustration-sale-leaseback-bulldozer.svg"
                    />
                    <ItemText>
                        <Heading size={3}>
                            Kapitaal vrij maken voor je bedrijf met Sale &amp;
                            Lease Back
                        </Heading>
                        <Text>
                            Je kunt de indicatie gebruiken om er achter te komen
                            of Sale &amp; Lease Back bij Beequip een mogelijke
                            optie is voor je bedrijf.
                        </Text>
                    </ItemText>
                </Item>
                <Item>
                    <Image src="/illustration-lock-truck.svg" />
                    <ItemText firstInRow>
                        <Heading size={3}>Je zit nergens aan vast</Heading>
                        <Text>
                            De waardebepaling is volledig vrijblijvend. Wel heb
                            je de mogelijkheid om te kijken of Sale &amp; Lease
                            Back een aantrekkelijke optie is voor je bedrijf.
                        </Text>
                    </ItemText>
                </Item>
            </Container>
        </ItemsSection>
    </>
)
