import React from 'react'
import styled, { css } from 'styled-components'
import { Button, Text, media } from '@beequip/hexagon'
import { Container } from './layout/Container'
import { LicensePlateForm } from './LicensePlateForm'

interface Props {
    onManualClick: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
}

const ExtendedContainer = styled(Container)(
    () => css`
        display: grid;
        grid-gap: 20px;
        position: relative;
        margin-top: -100px;
        margin-bottom: 80px;

        ${media.xs`
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        `}
    `
)

const ShadowCard = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: ${theme.spacing[2]}px;
        background-color: ${theme.colors.primaryLightBackground};
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        border: ${(props) => `${props.theme.colors.grey[4]} 1px solid`};
        border-radius: ${(props) => `${props.theme.borderRadius.default}px`};

        ${media.s`
            padding: ${theme.spacing[2]}px 40px;
        `}

        ${media.m`
            padding: ${theme.spacing[4]}px 80px;
        `}
    `
)

/**
 * 1. Without min-width, this item will be wider than
 *    the CSS grid defined by its parent.
 */
const TruckIllustration = styled.div`
    display: flex;
    min-width: 0; /* [1] */
    margin-top: -90px;
    padding-top: 110px;
    background-image: url('/illustration-truck-with-drill.svg');
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: top center;

    ${media.s`
        margin-top: -120px;
        padding-top: 140px;
        background-size: 331px;
    `}
`

/**
 * 1. Without min-width, this item will be wider than
 *    the CSS grid defined by its parent.
 */
const ShovelIllustration = styled.div`
    display: flex;
    min-width: 0; /* [1] */
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: top center;

    ${media.xs`
        margin-top: -120px;
        padding-top: 140px;
    `}

    ${media.s`
        background-size: 331px;
        background-image: url('/illustration-shovel.svg');
    `}
`

export const MachineEntryBoxes = ({ onManualClick }: Props) => {
    return (
        <ExtendedContainer maxWidth={936}>
            <TruckIllustration>
                <ShadowCard>
                    <Text bottomMargin={1}>Vul je kenteken in</Text>
                    <LicensePlateForm />
                </ShadowCard>
            </TruckIllustration>
            <ShovelIllustration>
                <ShadowCard>
                    <Text bottomMargin={1}>
                        Heeft je machine geen kenteken?
                    </Text>
                    <Button
                        kind="tertiary"
                        hook="manual-machine-entry-button"
                        onClick={onManualClick}
                    >
                        Vul gegevens zelf in
                    </Button>
                </ShadowCard>
            </ShovelIllustration>
        </ExtendedContainer>
    )
}
