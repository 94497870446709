import React from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { LandingLayout } from '@beequip/components/layout/_landingLayout'
import { MachineEntryBoxes } from '@beequip/components/MachineEntryBoxes'
import { useStore } from 'lib/useStore'
import { Explanation } from '@beequip/components/Explanation'

export default function Home() {
    const { setHasLeases, setHasDataFromRdw } = useStore()
    const router = useRouter()

    return (
        <>
            <NextSeo
                title="Bereken de dagwaarde"
                description="Snel en gratis afschrijving berekenen en de dagwaarde bepalen | Bereken de dagwaarde van jouw machine | Wat is mijn machine waard?"
            />

            <LandingLayout
                title="Bereken direct de dagwaarde van je machine"
                subtitle="Vul de gegevens in van jouw machine en ontdek direct de waarde."
            >
                <MachineEntryBoxes
                    onManualClick={() => {
                        setHasLeases(false)
                        setHasDataFromRdw(false)
                        router.push('/machine')
                    }}
                />
                <Explanation />
            </LandingLayout>
        </>
    )
}
