import React from 'react'

export const ArrowDownIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
    >
        <path
            fill="#FBBC01"
            d="M37.54 30.72a2 2 0 00-2.82-.26L26 37.72V6a2 2 0 10-4 0v31.72l-8.72-7.26a2.002 2.002 0 10-2.56 3.08l12 10 .3.18.26.14a2 2 0 001.44 0l.26-.14.3-.18 12-10a2 2 0 00.26-2.82z"
        />
    </svg>
)
