import React from 'react'
import { useRouter } from 'next/router'
import { Formik, FormikHelpers } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Button, FieldError, media } from '@beequip/hexagon'
import { LicensePlateInput } from './LicensePlateInput'
import { useStore } from '../lib/useStore'
import { post } from '../lib/http'
import { LeasesByObjectBrandType, VehicleSearch } from '../lib/types'
import { analyticsEvent } from '../lib/analytics'

interface FormValues {
    licensePlate?: string
}

const validationSchema = Yup.object().shape({
    licensePlate: Yup.string()
        .min(6, 'Een kenteken bestaat uit 6 tekens')
        .max(8, 'Een kenteken bestaat uit maximaal 8 tekens')
        .required('Vul alsjeblieft een kenteken in'),
})

const RowForm = styled.form`
    display: flex;
    flex-direction: column;

    ${media.s`
        flex-direction: row;
    `}
`

const ExtendedButton = styled(Button)`
    width: auto;
    align-self: flex-start;
    padding-right: 24px;
    padding-left: 24px;
    border-width: 0;

    ${media.s`
        align-self: initial;
    `}
`

export const LicensePlateForm = () => {
    const router = useRouter()
    const {
        licensePlate,
        setLicensePlate,
        setHasDataFromRdw,
        setObjectFromLease,
        setObjectFromRdw,
    } = useStore()
    const initialValues: FormValues = {
        licensePlate: licensePlate,
    }

    const onSubmit = async (
        values: FormValues,
        { setSubmitting }: FormikHelpers<FormValues>
    ) => {
        setLicensePlate(values.licensePlate ?? '')

        const vehicleResponse = await post<VehicleSearch>('/api/vehicle-info', {
            licensePlate: values.licensePlate,
        })
        const vehicle = vehicleResponse.rdwVehicleSearch
        if (vehicle === null) {
            // The vehicle has no valid license plate, so redirect to the form
            // where the user has to enter everything manually
            setHasDataFromRdw(false)
            setSubmitting(false)
            analyticsEvent({
                event: 'no-license-plate-info-found',
                state: {
                    licensePlate: values.licensePlate,
                    hasDataFromRdw: false,
                    hasLeases: false,
                },
            })
            router.push('/machine')
            return
        }

        setHasDataFromRdw(true)

        const leaseResponse = await post<LeasesByObjectBrandType>(
            '/api/leases',
            {
                brand: vehicle?.objectBrand,
                type: vehicle?.objectType,
            }
        )
        const leasesByObjectBrandType = leaseResponse.leasesByObjectBrandType
        const hasLeases =
            leasesByObjectBrandType === null
                ? false
                : leasesByObjectBrandType.leases.length > 0
        if (leasesByObjectBrandType === null || !hasLeases) {
            // The vehicle does have a license plate, but we haven't done
            // any leases with this type of vehicle, so redirect to the
            // the form where the user can enter the category and purchase
            // details
            const objectFromRdw = {
                brand: vehicle?.objectBrand as string,
                type: vehicle?.objectType as string,
                year: new Date(
                    vehicle?.objectFirstRegistrationDate
                ).getFullYear(),
            }
            setObjectFromRdw(objectFromRdw)

            analyticsEvent({
                event: 'license-plate-info-but-no-leases',
                state: {
                    licensePlate: values.licensePlate,
                    hasDataFromRdw: true,
                    hasLeases: false,
                    ...objectFromRdw,
                },
            })

            setSubmitting(false)
            router.push('/machine')
            return
        }

        // The vehicle has a license plate, and we have leases
        // with this type of vehicle in the Beehive, so read
        // the category from the lease, and redirect to the form where
        // the user can enter purchase details
        const objectFromLease = {
            brand: vehicle?.objectBrand as string,
            type: vehicle?.objectType as string,
            year: new Date(vehicle?.objectFirstRegistrationDate).getFullYear(),
            categoryGroupId:
                leasesByObjectBrandType.leases[0].category.categoryGroup.id,
            categoryId: leasesByObjectBrandType.leases[0].category.id,
            hasLeases: true,
        }
        setObjectFromLease(objectFromLease)

        analyticsEvent({
            event: 'license-plate-info-and-leases',
            state: {
                licensePlate: values.licensePlate,
                hasDataFromRdw: true,
                ...objectFromLease,
            },
        })

        setSubmitting(false)
        router.push('/machine')
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <>
                    <RowForm onSubmit={handleSubmit}>
                        <LicensePlateInput
                            hook="license-plate-form-input"
                            name="licensePlate"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.licensePlate}
                        />

                        <ExtendedButton
                            hook="license-plate-form-submit"
                            type="submit"
                        >
                            {isSubmitting ? 'Laden …' : 'Check'}
                        </ExtendedButton>
                    </RowForm>
                    {errors.licensePlate && touched.licensePlate && (
                        <FieldError>{errors.licensePlate}</FieldError>
                    )}
                </>
            )}
        </Formik>
    )
}
