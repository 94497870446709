import React from 'react'
import styled from 'styled-components'
import { Heading, Text, media } from '@beequip/hexagon'
import { Container } from './Container'

interface Props {
    title: string
    subtitle: string
}

const Wrapper = styled.div`
    position: relative;
    height: 400px;
    background-color: #00b7ff;

    ${media.m`
        height: 608px;
    `}
`

const TextWrapper = styled(Container)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    color: ${(props) => props.theme.colors.white};

    ${media.m`
        align-items: center;
        padding-top: 80px;
    `}
`

const HugeHeading = styled(Heading)`
    color: ${(props) => props.theme.colors.white};
    margin-bottom: ${(props) => props.theme.spacing[2]}px;

    ${media.m`
        margin-bottom: ${(props) => props.theme.spacing[4]}px;
        line-height: 80px;
        font-size: 64px;
        text-align: center;
    `}

    ${media.l`
        width: 75%;
    `}
`

const HugeText = styled(Text)`
    position: relative;
    z-index: 1;
    font-size: ${(props) => props.theme.font.sizes.m};

    ${media.m`    
        font-size: ${(props) => props.theme.font.sizes.xl};
    `}
`

export const Jumbotron = ({ title, subtitle }: Props) => (
    <Wrapper>
        <TextWrapper>
            <HugeHeading>{title}</HugeHeading>
            <HugeText color="light" weight="normal">
                {subtitle}
            </HugeText>
        </TextWrapper>
    </Wrapper>
)

Jumbotron.displayName = 'Jumbotron'
