import React from 'react'
import { motion } from 'framer-motion'
import { Header } from './Header'
import { Jumbotron } from './Jumbotron'
import { Footer } from './Footer'

interface Props {
    title: string
    subtitle: string
    children: React.ReactNode
}

export const LandingLayout = ({ title, subtitle, children }: Props) => (
    <>
        <Header />
        <Jumbotron title={title} subtitle={subtitle} />
        <motion.div
            initial={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
        >
            <main>{children}</main>
        </motion.div>
        <Footer />
    </>
)

LandingLayout.displayName = 'LandingLayout'
