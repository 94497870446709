import React from 'react'
import { FormikHandlers } from 'formik'
import styled, { css } from 'styled-components'
import { media } from '@beequip/hexagon'

type Props = Pick<FormikHandlers, 'handleChange' | 'handleBlur'> & {
    name: string
    hook: string
    value?: string
}

const Frame = styled.div(
    ({ theme }) => css`
        display: inline-block;
        align-self: flex-start;
        position: relative;
        padding: 2px 0 2px 24px;
        margin-bottom: ${theme.spacing[2]}px;
        background-color: #fbbc01;
        border-radius: ${theme.borderRadius.default}px;
        font-size: ${theme.font.sizes.xl};
        font-weight: bold;

        ${media.s`
            margin-bottom: 0;
            margin-right: ${theme.spacing[2]}px;
        `}

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            content: 'NL';
            width: 25px;
            height: 100%;
            padding: 30px 4px 0 6px;
            background-color: #039;
            background-image: url('/european-stars.svg');
            background-repeat: no-repeat;
            background-position: 4px 6px;
            color: #fff;
            font-size: 10px;
            border-radius: ${theme.borderRadius.default}px 0 0
                ${theme.borderRadius.default}px;
        }
    `
)

const Input = styled.input`
    width: 154px;
    padding: 10px;
    border-width: 0;
    background-color: transparent;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
`

export const LicensePlateInput = ({
    hook,
    name,
    handleChange,
    handleBlur,
    value,
}: Props) => {
    return (
        <Frame>
            <Input
                data-cy={hook}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                maxLength={8}
            />
        </Frame>
    )
}
